window.onresize = function(){
    getSize();
};

window.oncreate = function(){
    getSize();
};

function getSize(){
    var deviceSize = document.documentElement.clientWidth;
    console.log("设备宽度：", deviceSize);
    var fontSize = (deviceSize /20);
    console.log("字体大小：", fontSize+"px");
    // debugger;
    document.body.style.fontSize = fontSize + "px";
    document.documentElement.style.fontSize = fontSize + "px";
    //全屏
    document.body.style.margin = "0";
    document.documentElement.style.margin = "0";
}

getSize();