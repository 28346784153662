<template>
  <div class="main">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <!-- <div class="page1 swiper-slide">
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">基本信息</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">姓名</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">工号</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">入司时间</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">2023年度发薪月数</div>
        </div> -->
        <div class="page2 swiper-slide">
          <div class="page2-content-item1">
            <strong class="title">基本信息</strong>
            <div class="item">
              <span class="left">姓名：</span>
              <span class="right">张三</span>
            </div>
            <div class="item">
              <div class="left">工号：</div>
              <div class="right">13213131</div>
            </div>
            <div class="item">
              <div class="left">入司时间：</div>
              <div class="right">2023年1月</div>
            </div>
            <div class="item">
              <div class="left">年度发薪月数：</div>
              <div class="right">12月</div>
            </div>
          </div>
          <div class="page2-content-item2">
            <strong class="title">薪酬标准</strong>
            <div class="item">
              <div class="left">年目标薪资</div>
              <div class="right">100万元</div>
            </div>
            <div class="item">
              <div class="left">年基本薪资</div>
              <div class="right">100万元</div>
            </div>
            <div class="item">
              <div class="left">年绩效薪资</div>
              <div class="right">100万元</div>
            </div>
            <div class="item">
              <div class="left">满年奖</div>
              <div class="right">100万元</div>
            </div>
            <div class="item">
              <div class="left">薪酬固浮比</div>
              <div class="right">110%</div>
            </div>
          </div>
        </div> 
        <div class="page3 swiper-slide">
          <div class="total-num">
            <span class="total-num-content">32万元</span>
          </div>
          <div class="content-item">
            <div class="left">年度基本工资</div>
            <div class="right">100万元</div>
          </div>
          <div class="content-item">
            <div class="left">年度绩效工资</div>
            <div class="right">100万元</div>
          </div>
          <div class="content-item">
            <div class="left">满年奖</div>
            <div class="right">100万元</div>
          </div>
          <div class="content-item">
            <div class="left">各类奖励</div>
            <div class="right">100万元</div>
          </div>
        </div>
        <div class="page4 swiper-slide">
          <div class="page4-content-item">
            <div class="item">
              <div class="left">Q1</div>
              <div class="right">100万元</div>
            </div>
            <div class="item">
              <div class="left">Q2</div>
              <div class="right">200万元</div>
            </div>
            <div class="item">
              <div class="left">Q3</div>
              <div class="right">300万元</div>
            </div>
            <div class="item">
              <div class="left">Q4</div>
              <div class="right">400万元</div>
            </div>
          </div>
        </div>
        <div class="page5 swiper-slide">
          <div class="page5-content-item">
            <div class="item">
              <div class="left">2月</div>
              <div class="right">100元</div>
            </div>
            <div class="item">
              <div class="left">3月</div>
              <div class="right">100元</div>
            </div>
            <div class="item">
              <div class="left">4月</div>
              <div class="right">100元</div>
            </div>
            <div class="item">
              <div class="left">5月</div>
              <div class="right">100元</div>
            </div>
            <div class="item">
              <div class="left">6月</div>
              <div class="right">100元</div>
            </div>
            <div class="item">
              <div class="left">7月</div>
              <div class="right">100元</div>
            </div>
            <div class="item">
              <div class="left">8月</div>
              <div class="right">100元</div>
            </div>
            <div class="item">
              <div class="left">9月</div>
              <div class="right">100元</div>
            </div>
            <div class="item">
              <div class="left">10月</div>
              <div class="right">100元</div>
            </div>
            <div class="item">
              <div class="left">11月</div>
              <div class="right">100元</div>
            </div>
            <div class="item">
              <div class="left">12月</div>
              <div class="right">100元</div>
            </div>
            <div class="item">
              <div class="left">1月</div>
              <div class="right">100元</div>
            </div>
          </div>
          
          <!-- <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">3月</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">4月</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">5月</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">6月</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">7月</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">8月</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">9月</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">10月</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">11月</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">12月</div>
          <div class="swiper-slide-content ani" swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="0">1月</div> -->
        </div>
      </div>
    </div>
    </div>
</template>
 
<script>
import "animate.css";
import {watermark} from "watermark-dom";
import * as swiperAni from "../assets/animate";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";

export default {
  name: "AnnualBill",
  data() {
    return {}
  },
  methods: {
  },
  mounted() {
    watermark.load({watermark_txt: "hello world"});
  },
  created() {
    this.$nextTick(function() {
      const mySwiper = new Swiper(".swiper-container", { // eslint-disable-line no-unused-vars
        loop: false, // 循环模式选项
        speed: 500,
        direction: "vertical",
        effect:'coverflow',
        onInit: (swiper) => {
          swiperAni.swiperAnimateCache(swiper) // 隐藏动画元素
          swiperAni.swiperAnimate(swiper) // 初始化完成开始动画
        },
        onSlideChangeStart: (swiper) => {
          swiperAni.swiperAnimate(swiper) // 每个slide开始切换时也运行当前slide动画
          // swiper.slides.eq(this.activeIndex).find(".ani").removeClass("ani") // 动画只展现一次，去除ani类名
        }
      })
    })
  }
}
</script>
 
<style scoped lang="scss">
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}
.main {
  width: 100vw;
  height: 100vh;
  font-family:"Microsoft YaHei";
  .swiper-container {
    background-color: #020d35;
    height: 100vh;
    
    .swiper-slide {
      height: 100vh;
 
      // .swiper-slide-content {
        // width: 200px;
        // height: 200px;
        // margin: 0 auto;
      // }
    }

    .item{
      margin-top: 3%;
    }

    .page1{
      background-image: url(../assets/img/bill-bg1.png);
    }
    .page2{
      background-image: url(../assets/img/bill-bg2.png);
      background-size: 100% 100%;

      .title::before {
        content: "\002B";
        border-radius: 50%;
        display: inline-block;
        height: 8px;
        width: 8px;
        margin-right: 8px;
      }
      .page2-content-item1{
        margin-left: 12%;
        margin-top: 60%;
        width: 100%;
        .left{
          position:absolute;
        }
        .right{
          margin-left: 50%;
        }
      }

      .page2-content-item2{
        margin-left: 12%;
        margin-top: 10%;
        width: 100%;
        .left{
          position:absolute;
        }
        .right{
          margin-left: 50%;
        }
      }
    }
    .page3{
      background-image: url(../assets/img/bill-bg3.png);
      background-size: 100% 100%;

      .total-num{
        background-image: url(../assets/img/total-num.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 57%;
        height: 7%;
        margin-top: 90%;
        margin-left: 23%;
        .total-num-content{
          text-align: right;
          display: grid;
          height: 100%;
          align-items: center;
          margin-right: 10%;
        }
      }
      .content-item{
      margin-left: 15%;
      margin-top: 5%;
      width: 100%;
      .left{
        position:absolute;
      }
      .right{
        margin-left: 50%;
      }
    }
    }
    .page4{
      background-image: url(../assets/img/bill-bg4.png);
      background-size: 100% 100%;
      .page4-content-item{
      margin-left: 15%;
      margin-top: 105%;
      width: 100%;
      .left{
        position:absolute;
      }
      .right{
        margin-left: 50%;
      }
    }
    }
    .page5{
      background-image: url(../assets/img/bill-bg5.png);
      background-size: 100% 100%;
      .page5-content-item{
      margin-left: 15%;
      margin-top: 50%;
      width: 100%;
      .left{
        position:absolute;
      }
      .right{
        margin-left: 50%;
      }
    }
    }
  }
}
</style>