import Vue from 'vue'
import App from './App.vue'

import 'amfe-flexible'
import './utils/rem.js'
// import animated from 'animate.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'


Vue.config.productionTip = false

// 全局挂载
Vue.use(VueAwesomeSwiper)
// Vue.use(animated)

new Vue({
  render: h => h(App),
}).$mount('#app')
